.btnAdd {
  display: flex;
  justify-content: end;
}

.heading1 {
  margin-left: 45px;
  font-weight: 600;
}

.btntop {
  margin-left: auto;
}

.buttontop {
  background: rgb(71, 71, 71);
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 5px 8px;
}